<template>
    <div class="list-withdraw">
        <div class="d-flex justify-content-end mb-3">
            <div class="wrap-filter">
                <b-button class="btn btn-info btn-sm mr-1" @click="isShowFilter = !isShowFilter">Filter</b-button>
            </div>
        </div>
        <filter-withdraw v-if="isShowFilter" :typeTable="typeWithdraw" @setDataFilter="setFilterTable" :dataClient="dataClient"></filter-withdraw>
        <div class="view-perpages">
            <select v-model="perPage" @change="showDataRow">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <span>&nbsp;entries per page</span>
        </div>
        <table>
            <thead>
                <tr id="top">
                    <th scope="col" style="width: 70px">No</th>
                    <th scope="col">Owner</th>
                    <th scope="col">Withdrawal Date</th>
                    <th scope="col">Total Item</th>
                    <th scope="col">Total Weight</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody v-if="isLoadingData">
                <tr>
                    <td colspan="7">
                        <b-spinner></b-spinner>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(data, i) in withdrawalData" :key="i" v-show="withdrawalData != 0">
                    <td data-label="No">{{ (pageNumber - 1) * totalPerPage + (i + 1) }}</td>
                    <td data-label="Transaction">{{ data.owner }}</td>
                    <td data-label="Deposite Date">{{ data.created_at | formatDate }}</td>
                    <td data-label="Total Item">{{ data.total_item }}</td>
                    <td data-label="Total Weight">{{ data.total_weight }}</td>

                    <td data-label="Status">
                        <div v-if="typeWithdraw == 'onexchange'">
                            <span v-show="data.status == '001'" class="abx">Admin KMI Request Withdrawal </span>
                            <span v-show="data.status == '002'" class="operator">Admin KBI Apporve </span>
                            <span v-show="data.status == '003'" class="clearing">Operator KMI Confirmation (Stored)</span>
                        </div>
                        <div v-else>
                            <span v-show="data.status == '001'" class="vendor">Vendor Emas Add Detail </span>
                            <span v-show="data.status == '002'" class="abx">KMI Approve, Vendor store the gold</span>
                            <span v-show="data.status == '003'" class="operator">Operator Approve</span>
                            <span v-show="data.status == '004'" class="clearing">Clearing Approve</span>
                            <span v-show="data.status == '005'" class="stored">Stored</span>
                            <span v-show="data.status == '006'" class="reject">Reject</span>
                            <span v-show="data.status == '007'" class="stored">Has Been Withdrawn</span>
                        </div>
                    </td>
                    <td data-label="Action">
                        <router-link :to="'/on-exchange/withdrawal/' + data._id">
                            <b-button variant="outline-info" size="sm" class="m-1">Detail</b-button>
                        </router-link>
                    </td>
                </tr>
                <tr v-show="withdrawalData.length == 0">
                    <td colspan="7">No Data Available in Table</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import FilterWithdraw from "../FilterWithdraw.vue";
// import FilterListDeposit from "../utility/filter/FilterListDeposit.vue";
export default {
    components: { FilterWithdraw },
    props: {
        withdrawalData: Array,
        typeWithdraw: {
            type: String,
            default: "onexchange",
        },
        pageNumber: Number,
        totalPerPage: Number,
        totalWithdraw: Number,
        isLoadingData: Boolean,
        dataClient: Array,
    },
    data() {
        return {
            isShowFilter: false,
            perPage: this.totalPerPage,
        };
    },
    methods: {
        setFilterTable(event) {
            this.$emit("filterTable", event);
        },
        showDataRow() {
            this.$emit("changeRow", this.perPage);
        },
    },
};
</script>

<style></style>
