<template>
    <div class="list-metal">
        <div class="text-right mb-3">
            <b-button class="btn btn-info mr-1 btn-sm" @click="showFilter" v-if="role != 9">Filter</b-button>
        </div>
        <filter-table v-if="isShowFilter" @setDataFilter="setDataFilterToParent" :dataClient="dataClient" :dataContract="dataContract"></filter-table>
        <table>
            <thead>
                <tr id="top">
                    <th scope="col" style="width: 70px">No</th>
                    <th scope="col">Serial Number</th>
                    <th scope="col">Year</th>
                    <th scope="col" style="width: 120px">owner</th>
                    <th scope="col" style="width: 200px">Deposit Date</th>
                    <th scope="col" style="width: 120px" v-if="typeWithdrawal == 'offexchange'">Deposit Due Date</th>
                    <th scope="col">Fineness</th>
                    <th scope="col">Hallmark</th>
                    <th scope="col">Weight (gr)</th>
                    <th scope="col">Status</th>
                    <!-- <th scope="col" v-if="role != 8">Action</th> -->
                </tr>
            </thead>
            <tbody v-if="isLoadingData">
                <tr>
                    <td colspan="10">
                        <b-spinner></b-spinner>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(data, i) in metalData" :key="i" v-show="metalData.length != 0">
                    <td data-label="No">{{ (pageNumber - 1) * totalPerPage + (i + 1) }}</td>
                    <td data-label="Serial Number">{{ data.serial_number }}</td>
                    <td data-label="Year">{{ data.year }}</td>
                    <td data-label="Owner">{{ data.owner }}</td>
                    <td data-label="Deposite Date">{{ data.metal_date | formatDate }}</td>
                    <td data-label="Deposite Due Date" v-if="typeWithdrawal == 'offexchange'">{{ data.due_date | formatDate }}</td>
                    <td data-label="Fineness">{{ data.fineness }}</td>
                    <td data-label="Hallmark">{{ data.hallmark }}</td>
                    <td data-label="Weight">{{ data.weight }}</td>
                    <td data-label="Status">
                        <div>
                            <span v-show="data.status == '001'" class="vendor">Vendor Emas Add Detail </span>
                            <span v-show="data.status == '002'" class="abx">KMI Approve</span>
                            <span v-show="data.status == '003'" class="operator">Operator Approve</span>
                            <span v-show="data.status == '004'" class="clearing">Clearing Approve</span>
                            <span v-show="data.status == '005'" class="stored">Stored</span>
                            <span v-show="data.status == '006'" class="reject">Reject</span>
                            <span v-show="data.status == '007'" class="stored">Withdrawal Process</span>
                            <span v-show="data.status == '008'" class="stored">Has Been Withdrawn</span>
                        </div>
                    </td>

                    <!-- <td data-label="Action" v-if="role != 8"> -->
                    <!-- <b-button variant="outline-danger" size="sm" class="m-1" v-show="role == 2 && data.status == '003'">Decline</b-button>
                  <b-button variant="outline-success" size="sm" class="m-1" @click="sendId(data._id)" v-show="role == 1 && data.status == 1">Approve</b-button>
                  <b-button variant="outline-success" size="sm" class="m-1" @click="sendId(data._id)" v-show="role == 2 && data.status == 2">Approve</b-button> -->
                    <!-- <router-link :to="'/operator-detail-deposit/' + data._id" v-if="role == 5">
                            <b-button variant="outline-info" size="sm" class="m-1">Detail</b-button>
                        </router-link> -->
                    <!-- <router-link :to="'/detail-deposit/' + data._id" v-else>
                            <b-button variant="outline-info" size="sm" class="m-1">Detail</b-button>
                        </router-link> -->
                    <!-- </td> -->
                </tr>
                <tr v-show="metalData.length == 0">
                    <td colspan="10">No Data Available in Table</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";
import FilterTable from "../FilterTable.vue";
export default {
    components: { FilterTable },
    props: {
        metalData: Array,
        pageNumber: Number,
        totalPerPage: Number,
        dataContract: Array,
        dataClient: Array,
        isLoadingData: Boolean,
        totalData: Number,
        typeWithdrawal: {
            type: String,
            default: "onexchange",
        },
    },
    data() {
        return {
            role: sessionStorage.getItem("role"),
            isLoading: false,
            isShowFilter: false,
            clientSelected: {
                id: "",
                owner: "",
            },
            form: {
                info: "",
                vendor_id: "",
                codeexc: "",
                owner: "",
                metal_type: "gold",
                metal_form: "",
                deposite_date: "",
                fineness: "",
                hallmark: "",
                weight: Number(),
                serial_number: "",
                year: Number(),
            },
            today: "",
            activity: "deposit",
            owner: "",
            startDate: "",
            endDate: "",
            serial_number: "",
            hallmark: "",
            status: "",
            date_type: "",
            perPage: 10,
            currentPage: 1,
            contract_id: "",
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        // axios.get("/v2/clearing/admin/abx/get/list_depositor?type=offexchange").then((res) => {
        //     this.dataClient = res.data.data.body;
        // });
    },
    methods: {
        showModal(modalRef) {
            this.$refs[modalRef].show();
        },
        showFilter() {
            this.isShowFilter = !this.isShowFilter;
            this.$emit("setShowFilter", this.isShowFilter);
        },
        setDataFilterToParent(event) {
            this.$emit("setFilterTable", event);
        },
        addDeposite() {
            this.form.vendor_id = this.clientSelected;
            this.isLoading = true;
            this.$store.dispatch("getLocation").then((resGeo) => {
                if (resGeo.cityData[0]) {
                    this.form.info = JSON.stringify(resGeo);
                } else {
                    let data = resGeo;
                    data.newLatLong = resGeo.cityLatLong.split(",");
                    this.form.info = JSON.stringify(data);
                }
                const dataAddDeposit = {
                    info: this.form.info,
                    vendor_id: this.clientSelected.id,
                    codeexc: this.form.codeexc,
                    owner: this.clientSelected.owner,
                    metal_type: this.form.metal_type,
                    metal_form: this.form.metal_form,
                    deposite_date: this.form.deposite_date,
                    fineness: this.form.fineness,
                    hallmark: this.form.hallmark,
                    weight: this.form.weight,
                    serial_number: this.form.serial_number,
                    year: this.form.year,
                };
                axios.post("/v2/clearing/admin/abx/offexchange/deposit/add", dataAddDeposit).then((res) => {
                    if (!res.data.iserror) {
                        this.$toast.success("Add Deposit Success");
                        this.isLoading = false;
                        setTimeout(() => {
                            location.reload();
                        }, 2000);
                    }
                });
            });
        },
    },
};
</script>

<style scoped>
.form-group label {
    width: 30%;
}

.form-input {
    width: 70%;
    border-radius: 5px;
    border: 1px solid #454545;
    padding: 5px 0.5rem;
    font-size: 13px;
}
</style>
