<template>
    <div class="wrap-dashboard">
        <div class="main-content">
            <div class="nav-tab">
                <span class="tab" :class="{ activeTab: typePage == 'withdraw' }" @click="filterTypeWithdraw('withdraw')">List Withdraw</span>
                <span class="tab" :class="{ activeTab: typePage == 'metal' }" @click="filterTypeWithdraw('metal')">List Metal</span>
            </div>
            <list-withdraw
                v-if="typePage == 'withdraw'"
                :withdrawalData="getWithdrawData"
                :typeWithdraw="typeWithdraw"
                :pageNumber="currentPage"
                :totalPerPage="perPage"
                :totalWithdraw="getTotalData"
                :isLoadingData="getIsLoading"
                :dataClient="getDataClient"
                @filterTable="filterTable"
                @changeRow="onChangeRow"
            ></list-withdraw>
            <list-metal-withdraw
                v-else
                :metalData="getWithdrawData"
                :pageNumber="currentPage"
                :totalPerPage="perPage"
                :dataContract="getDataContract"
                :dataClient="getDataClient"
                :isLoadingData="getIsLoading"
                :totalData="getTotalData"
                :typeDeposit="typeWithdraw"
                @setFilterTable="filterTable"
            ></list-metal-withdraw>

            <div class="d-flex justify-content-end mt-3" v-if="!getIsLoading">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="getTotalData"
                    :per-page="perPage"
                    @change="handlePageChange"
                    aria-controls="new-table"
                    first-number
                    last-number
                    v-show="getTotalData > 10"
                ></b-pagination>
            </div>
            <b-modal ref="modal-face-recognition" size="lg" title="Face Recognition">
                <face-recognition></face-recognition>
            </b-modal>
        </div>
    </div>
</template>

<script>
import ListWithdraw from "./ListWithdraw.vue";
import ListMetalWithdraw from "./ListMetalWithdraw.vue";

export default {
    components: { ListWithdraw, ListMetalWithdraw },
    data() {
        return {
            dataClient: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            offset: 0,
            typeWithdraw: "onexchange",
            typePage: "withdraw",
            activity: "withdrawal",
            owner: "",
            startDate: "",
            endDate: "",
            status: "",
            serialNumber: "",
            hallmark: "",
            contract_id: "",
            dateType: "",
        };
    },

    mounted() {
        this.getData(
            this.currentPage,
            this.perPage,
            this.typeWithdraw,
            this.owner,
            this.startDate,
            this.endDate,
            this.status,
            this.serialNumber,
            this.activity,
            this.hallmark,
            this.dateType,
            this.contract_id
        );

        this.$store.dispatch("getContract");
        this.$store.dispatch("getClientData", this.typeWithdraw);
    },
    methods: {
        openModal() {
            this.$refs["modal-face-recognition"].show();
        },
        handlePageChange(value) {
            this.getData(
                value,
                this.typeWithdraw,
                this.owner,
                this.startDate,
                this.endDate,
                this.status,
                this.serialNumber,
                this.activity,
                this.hallmark,
                this.dateType,
                this.contract_id
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        filterTypeWithdraw(value) {
            this.typePage = value;
            this.getData(
                this.currentPage,
                this.perPage,
                this.typeWithdraw,
                this.owner,
                this.startDate,
                this.endDate,
                this.status,
                this.serialNumber,
                this.activity,
                this.hallmark,
                this.dateType,
                this.contract_id
            );
        },
        filterTable(event) {
            this.owner = event.owner;
            this.startDate = event.startDate;
            this.endDate = event.endDate;
            this.status = event.status;
            this.serialNumber = event.serial_number;
            this.hallmark = event.hallmark;
            this.dateType = event.dateType;
            this.contract_id = event.contract_id;
            this.getData(
                this.currentPage,
                this.perPage,
                this.typeWithdraw,
                event.owner,
                event.startDate,
                event.endDate,
                event.status,
                event.serial_number,
                this.activity,
                this.hallmark,
                this.dateType,
                this.contract_id
            );
        },
        onChangeRow(event) {
            this.perPage = event;
            this.getData(
                this.currentPage,
                this.perPage,
                this.typeWithdraw,
                this.owner,
                this.startDate,
                this.endDate,
                this.status,
                this.serialNumber,
                this.activity,
                this.hallmark,
                this.dateType,
                this.contract_id
            );
        },
        getData(currentPage, perPage, type, owner, startDate, endDate, status, serialNumber, activity, hallmark, dateType, contract_id) {
            if (this.typePage == "withdraw") {
                this.$store.dispatch("getWithdraw", {
                    currentPage,
                    perPage,
                    type,
                    owner,
                    startDate,
                    endDate,
                    status,
                    serialNumber,
                });
            } else {
                this.$store.dispatch("getListMetal", {
                    activity: activity,
                    perPage: perPage,
                    currentPage: currentPage,
                    startDate: startDate,
                    endDate: endDate,
                    type: type,
                    owner: owner,
                    serialNumber: serialNumber,
                    hallmark: hallmark,
                    dateType: dateType,
                    status: status,
                    contract_id: contract_id,
                });
            }
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        getWithdrawData() {
            return this.$store.state.bullionModule.dataShow;
        },
        getTotalData() {
            return this.$store.state.bullionModule.lengthData;
        },
        getIsLoading() {
            return this.$store.state.bullionModule.isLoadingWithdraw;
        },
        getDataContract() {
            return this.$store.state.bullionModule.contractData;
        },
        getDataClient() {
            return this.$store.state.bullionModule.clientData;
        },
    },
};
</script>

<style scoped>
.nav-tab {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #636366;
    padding: 0.8rem 0;
}

.tab {
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.tab:hover,
.activeTab {
    color: var(--scondary-color);
    border-bottom: 2px solid var(--scondary-color);
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

label {
    width: 50%;
}

.select-input,
input {
    width: 100%;
}

.form-group {
    display: flex;
}

.select-input,
input {
    border-radius: 5px;
    border: 1px solid #454545;
    padding: 0 10px;
    font-size: 13px;
}

.buuton-add {
    text-align: right;
    font-size: 13px;
}

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    flex-wrap: wrap;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 109px;
}
select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.status-withdrawal {
    padding: 5px;
    display: inherit;
}

.request {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.abx {
    /* background: rgba(172, 143, 26, 0.1); */
    font-weight: 600;
    color: rgb(172, 143, 26);
    border-radius: 5px;
}
.operator {
    /* background: rgba(89, 37, 25, 0.1); */
    font-weight: 600;
    color: rgb(89, 37, 25);
    border-radius: 5px;
}
.kbi {
    /* background: rgba(60, 60, 92, 0.1); */
    font-weight: 600;
    color: rgb(60, 60, 92);
    border-radius: 5px;
}
.shipping {
    /* background: rgba(188, 180, 179, 0.1); */
    font-weight: 600;
    color: rgb(188, 180, 179);
    border-radius: 5px;
}
.handover {
    /* background: rgba(50, 90, 115, 0.1); */
    font-weight: 600;
    color: rgb(50, 90, 115);
    border-radius: 5px;
}
.kprk {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.nasabah {
    /* background: rgba(40, 167, 69, 0.1); */
    font-weight: 600;
    color: rgb(40, 167, 69);
    border-radius: 5px;
}
.decline {
    /* background: rgba(220, 53, 69, 0.1); */
    font-weight: 600;
    color: rgb(220, 53, 69);
    border-radius: 5px;
}
</style>
